<template>
	<div id="decision-modelo">
    <div class="container">
      <h2 class="text-center d-block	">Decisión Modelo</h2>
			<h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
			<h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>
			<div>
				<div class="container">
						<div class="generales-data">
							<h3 class="text-center final-call">
								Decisión Final: &nbsp; &nbsp;
								<b> {{ solicitud.variablesUnykoo.DesicionFinal }} </b>
							</h3>
						</div>
					</div>
			</div>

			<div class="negocio row col-md-12">
        <div class="col-md-12">
          <div class="business-heading">
            <h4> Descripción del Negocio </h4>
          </div>
          <div class="container content-tabla">
            <div class="generales-data">
              <span class="heanding-mobile">
                ¿Es empleado?
              </span>
              <span class="col text-right">
                {{ employeeStatus(solicitud.ServiciosOriginacion.es_empleado) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Giro del negocio
              </span>
              <span class="col text-right">
                {{ solicitud.ServiciosOriginacion.giro_negocio.segundo_nivel }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Descripción del negocio
              </span>
              <span class="col text-right">
                {{ solicitud.ServiciosOriginacion.descripcion_giro_negocio }}
              </span>
            </div>
						<template v-if="solicitud.Scoring.ekatena && solicitud.Scoring.ekatena.score">
            <div class="generales-data">
            	<span class="heading-mobile">Ekatena Score</span>
            	<span class="col text-right">{{ solicitud.Scoring.ekatena.score }}</span>
            </div>
						<div class="generales-data"><small class="col text-right">
							< 448 malo, 448-672 medio, >672 bueno
						</small></div>
					</template>
          </div>
        </div>
      </div>

			<div class='modelos row col-md-12'>
				<div class='col-sm-6'>
					<div class="content-tabla back-green">
						<div class="generales-data">
							<span class="heanding-mobile f-white">
								Score modelo v1
							</span>
							<span class="col text-right f-white">
								{{ formatRounded(solicitud.variablesUnykoo.PuntajeTotal) }}
							</span>
						</div>
						<div class="generales-data">
							<span class="heanding-mobile f-white">
								Decisión modelo v1
							</span>
							<span class="col text-right f-white">
								{{ solicitud.variablesUnykoo.ScoreV1 }}
							</span>
						</div>
					</div>
					
        
					<div class="col-md-12">
						<div class="content-tabla">
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje colateral
									</span>
									<span class="col text-right">
										{{ formatRounded(solicitud.variablesUnykoo.Colateral) }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_informacion_cualitativa_falsa']) }}
										</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
									Puntaje carácter
									</span>
									<span class="col text-right">
										{{ formatRounded(solicitud.variablesUnykoo.Caracter) }}
										<small>{{ riesgoAvatar }}</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje capacidad
									</span>
									<span class="col text-right">
										{{ formatRounded(solicitud.variablesUnykoo.Capacidad) }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_informacion_cuantitativa_falsa']) }}
										</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje capital
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.Capital }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_fraude']) }}
										</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje ciclo económico
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.CicloEconomico }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['comportamiento_economico']) }}
										</small>
									</span>
								</div>
								<hr class="d-block d-sm-none" />
								<div class="more-information">
									<a class="text-right" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
										<small> 
											Ver más información
											<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-up.svg" width="20px" />
										</small>
									</a>
								</div>
							</div>
								<div class="collapse" id="collapseExample1">
									<div class="card card-body">
											<div class="content-tabla">
												<div class="generales-data">
													<span class="heanding-mobile">
													Cuentas atraso menor
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.TotalCuentasAtrasoMenor }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Cuentas MOP 9x
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.TotalCuentasMop9x }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Tipo de comprobante
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.TipoComprobante }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Ingresos negocio
													</span>
													<span class="col text-right">
														${{ formatPrice(solicitud.Ocr.ingresosTotal) }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Relación pago/ingreso
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.PagoIngresos }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Relación deuda/ingreso
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.DeudaAIngresos }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Rentada
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.Rentada }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Capital 1 (otras propiedades)
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.CapitalUno }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Número de empleados
													</span>
													<span class="col text-right">
														{{ solicitud.Scoring.avatar['numero_empleados'] }}
													</span>
												</div>
												<div class="generales-data">
													<span class="heanding-mobile">
														Competencia
													</span>
													<span class="col text-right">
														{{ solicitud.variablesUnykoo.Competencia }}
													</span>
												</div>
											</div>
									</div>
								</div>
					</div>
				</div>
				<div class='col-sm-6'>
					<div class="content-tabla back-green">
						<div class="generales-data">
							<span class="heanding-mobile f-white">
								Score modelo v2
							</span>
							<span class="col text-right f-white">
								{{ formatRounded(solicitud.variablesUnykoo.SumaScore) }}
							</span>
						</div>
						<div class="generales-data f-white">
							<span class="heanding-mobile f-white">
								Decisión modelo v2
							</span>
							<span class="col text-right f-white">
								{{ solicitud.variablesUnykoo.ScoreV2 }}
							</span>
						</div>
					</div>
					
					<div class="col-md-12">
						<div class="content-tabla">
							<div class="generales-data">
									<span class="heanding-mobile">
									Puntaje cliente
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.ConceptoCliente }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_fraude'] ) }}
										</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje capacidad de pago
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.CapacidadPago }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_informacion_cuantitativa_falsa'] ) }}
										</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje colateral
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.ConceptoColateral }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_informacion_cualitativa_falsa']) }}
										</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje comportamiento pago
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.ComportamientoPago }}
										<small>{{ riesgoAvatar }}</small>
									</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">
										Puntaje negocio
									</span>
									<span class="col text-right">
										{{ solicitud.variablesUnykoo.ConceptoNegocio }}
										<small>
											{{ variablesAvatar(solicitud.Scoring.avatar['comportamiento_economico']) }}
										</small>
									</span>
								</div>
								<hr class="d-block d-sm-none" />
								<div class="more-information">
									<a class="text-right" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
										<small> 
											Ver más información 
											<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-up.svg" width="20px" />
										</small>
									</a>
								</div>
							</div>
							<div class="collapse" id="collapseExample">
								<div class="card card-body">
									<div class="content-tabla">
										<div class="generales-data">
											<span class="heanding-mobile">
											Desempeño promedio de las cuentas
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.DesempenoPromedioCuentas }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Desempeño promedio de los pagos
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.RendimientoPromedioPago }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												BC score
											</span>
											<span class="col text-right">
												{{ solicitud.Scoring.buro[0].score }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Porcentaje de atraso promedio
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.PorcentajePagoTranscurrido }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Deuda mensual crédito BpB
											</span>
											<span class="col text-right">
												${{ formatPrice(solicitud.variablesUnykoo.BpbDeudaMensual) }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Relación pago crédito BpB ingreso
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.BpbRelacionMensualDeudaIngresos }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Relación deuda mensual/ingreso
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.RelacionMensualDeudaIngresos }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Relación deuda vencida/ingreso
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.RelacionDeudaIngresoAnterior }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Relación deuda total/ingreso
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.DeudaTotalARazonIngresos }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Gravamen
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.Gravamen }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Relación valor propiedad/monto
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.ValorPropiedadProporcionCantidadSolicitada }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Habilitado por familia
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.HabitadoPorFamilia }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Score Opinión de valor
											</span>
											<span class="col text-right">
												{{ formatRounded(solicitud.opinionValor.score) }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Dueño del negocio
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.DuenoNegocio }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Nivel educativo
											</span>
											<span class="col text-right">
												{{ solicitud.Scoring.avatar['nivel_educativo'] }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Edad
											</span>
											<span class="col text-right">
												{{ solicitud.Scoring.avatar['edad'] }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Edad del negocio
											</span>
											<span class="col text-right">
												{{ solicitud.Scoring.avatar['antigüedad_negocio'] }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Temporalidad
											</span>
											<span class="col text-right">
												{{ solicitud.Scoring.avatar['temporalidad_negocio'] }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Alcance geográfico
											</span>
											<span class="col text-right">
												{{ solicitud.Scoring.avatar['alcance_geografico_negocio'] }}
											</span>
										</div>
										<div class="generales-data">
											<span class="heanding-mobile">
												Relación monto/ingreso del negocio
											</span>
											<span class="col text-right">
												{{ solicitud.variablesUnykoo.CantidadSolicitadaParaRelacionIngresosNegocio }}
											</span>
										</div>
									</div>
								</div>
							</div>
					</div>
				</div>
			</div>

			<div class='modelos row col-md-12'>
				<div class='col-sm-6'>
					<div class="content-tabla back-green">
						<div class="generales-data">
							<span class="heanding-mobile f-white">
								Score modelo v3
							</span>
							<span class="col text-right f-white">
								{{ solicitud.variablesUnykoo.scoreV3 }}
							</span>
						</div>
						<div class="generales-data">
							<span class="heanding-mobile f-white">
								Decisión modelo v3
							</span>
							<span class="col text-right f-white" v-if="solicitud.variablesUnykoo.scoreV3 <= 405">
								Rechazar
							</span>
							<span class="col text-right f-white" v-else-if="solicitud.variablesUnykoo.scoreV3 > 405 && solicitud.variablesUnykoo.scoreV3 <= 428">
								Discutir
							</span>
							<span class="col text-right f-white" v-else="solicitud.variablesUnykoo.scoreV3 > 428">
								Aceptar
							</span>
						</div>
					</div>
					
        
					<!--<div class="col-md-12">
						<div class="content-tabla">
							<div class="generales-data">
								<span class="heanding-mobile">
									Puntaje 
								</span>
								<span class="col text-right">
									.
								</span>
							</div>
							<div class="generales-data">
								<span class="heanding-mobile">
									Puntaje capital
								</span>
								<span class="col text-right">
									.
									<small>
									.
									</small>
								</span>
							</div>
						</div>
					</div>-->
				</div>
			</div>

		</div>
		<div v-if="cesion_creditos">
			<VotacionCesionCredito/>
    </div>
    <div v-else>
      <Votacion/>
    </div>
	</div>
</template>

<script>
  import Votacion from '@/apps/comite/pages/Votacion'
	import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'
export default {
  name: 'decision-modelo',
  components: {
    Votacion,
		VotacionCesionCredito,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      solicitud: null,
			cesion_creditos: false,
    }
   },

  created: function() {
    this.solicitud = this.$store.state.solicitud;    
  },
	
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

		if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
      this.cesion_creditos = true
    }
  },
	
	computed: {
    riesgoAvatar(){
      const riesgoPersona = this.solicitud.Scoring.avatar['riesgo_persona']
      let resultado = " ";
                  
      if (riesgoPersona <= 1 && riesgoPersona !== null){
        resultado = "(+.1)";
      } else if(riesgoPersona >= 1.1 && riesgoPersona <= 3.1) {
        resultado = "(0)";
      } else if(riesgoPersona > 3.1) {
        resultado = "(-.1)";
      } return resultado;
    },
  },
  
	methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    
    formatRounded(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")
    },

    employeeStatus(){
      const isEmployee = this.solicitud.ServiciosOriginacion.es_empleado
      let employee;

      if (isEmployee == 1) {
        employee = "Sí";
      } else if (isEmployee == 0){
        employee = "No";
      } else if (isEmployee == null){
        employee = "Sin información alimentada en sistema de originación"
      } return employee;
    },
    
    variablesAvatar(resultadoProspecto){
      resultadoProspecto=parseFloat(resultadoProspecto);
      
      let resultado = " ";  
      
      if (resultadoProspecto <= 4 && resultadoProspecto !== null){
        resultado = "(+.1)";
      } else if(resultadoProspecto >= 4.1 && resultadoProspecto <= 6.5) {
        resultado = "(0)";
      } else if(resultadoProspecto > 6.5){
        resultado = "(-.1)";
      } return resultado;
    }
  }
}

</script>

<style lang="scss" scoped>
  #decision-modelo {
    background-color: #fff;
    // min-height: 100vh; 
    margin-bottom: 100px;
    
    .card{
      border: none !important;
      &.card-body{
        padding: 0 !important;
      }
    }
    .negocio{
    	margin-bottom: 1rem;
	    .business-heading{
	    	background-color: $color-blue;
	      padding: 1rem;
	      margin: 1rem 0;
	      
	      h4 {
	        color: $white;
	      }
	      
	      @include mq-min(tablet){
	        padding: 2% 0 1% 6%;
	      }
	    }
    } 
    .back-green {
      background-color: $color-green;
      padding: 0.5rem 0.25rem;
      color: $white !important;
      margin: 0 0 1rem;
      span{
      	text-transform: capitalize;
      }
      .generales-data{
	      &:nth-of-type(even){
	        background: transparent;
	      }
      }
    }
    span{
    	line-height: 1.5;
    }
    .content-tabla {
      color: $text-primary;
      
      .generales-data {
        display: flex;
        align-items: center;
        
        .heanding-mobile {
          font-weight: 400;
          padding-left: 0;
          width: 60%;
        }
        .text-right {
          padding-right: 0;
          font-weight: 700;
        }
      }
      
      .f-white {
        color: #fff !important;
      }
    }
    @include mq-min(tablet){
     min-height: 49vh; 
    }
  }
  h2 {
    padding: 1rem 0;
    color: $text-primary;
  }
	h3 {
		color: $text-primary;
		margin-bottom: 2rem;
		font-weight: 400;
		&.final-call{
			color: $color-green;
		}
	}
	.more-information {
		text-align: right;
		margin: 2rem 0;

		a {
			img {
				&:focus {
					outline: none !important;
					box-shadow: none;
				}
				&[aria-expanded="true"] {
					.arrow-down {
						transition: all 0.8s;
						transform: rotate(-180deg);
					}
				}
				&[aria-expanded="false"] {
					.arrow-down {
						transition: all 0.8s;
					}
				}
			}
		}
	}
	
	@include mq-max(mobile-big){
		.modelos {
			margin: auto !important;
	    padding: 0 !important;
	    
	    .col-sm-6 {
	      padding: 0 !important;
	      margin-bottom: 10% !important;
	    }
		}
	}
</style>	